import { useTranslation } from 'react-i18next';
import { Dialog, Hoverable, Stack, Transition, Typography } from '@components/common';
import { DollarIcon } from '@components/icons';
import { useDineInOrderDetail } from '@hooks/dineIn';
import { DialogProps } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
}

const PayToCashierDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { dineInDetail: { tableNo = t('dine_in.table_no_placeholder') } = {} } = useDineInOrderDetail({
    enabled: false,
  });

  const handleClose: DialogProps['onClose'] = (_, reason) => {
    if (reason && reason == 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} borderRadius={'large'}>
      <Stack
        spacing={'xxl'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingLeft={'xxl'}
        paddingRight={'xxl'}
        paddingTop={'xxxl'}
        paddingBottom={'xxxl'}
        width={'300px'}
      >
        <DollarIcon height={24} width={24} />
        <Stack flexDirection={'column'} spacing={'xs'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant={'bold'} size={'m'}>
            {t('dine_in.pay_to_cashier_title')}
          </Typography>
          <Typography variant={'medium'} size={'s'}>
            {t('dine_in.pay_to_cashier_description', { tableNo })}
          </Typography>
        </Stack>
        <Hoverable onClick={onClose}>
          <Typography variant={'medium'} color={'uiLightStainSecondary'} size={'s'} opacity={0.4}>
            {t('order.change_payment_method_label')}
          </Typography>
        </Hoverable>
      </Stack>
    </Dialog>
  );
};

export default PayToCashierDialog;
