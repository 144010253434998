import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { v4 as uuidv4 } from 'uuid';
import { cancelPayClosedBillToCashier, createDineInCustomerOrder, payClosedBillToCashier } from '@api/dineIn';
import { CreateDineInCustomerOrderResponse } from '@api/dineIn/types';
import { createOnlinePayment } from '@api/order';
import {
  CreateOnlinePaymentRequest,
  CreditCardPaymentMethod,
  CustomerOrderPaymentResponse,
  CustomerOrderPriceDetail,
  PaymentMethod,
  ProductOrder,
} from '@api/order/types';
import { Button, Stack, Typography } from '@components/common';
import { PaymentMethodInput, UpdateProfileNameFormDialog } from '@components/order';
import { OrderPaymentDialog } from '@components/payments';
import { ORDER_DETAIL_REFETCH_INTERVAL_OPTIONS } from '@constants/order';
import routes from '@constants/routes';
import { FormProps } from '@containers/dineIn/ClosedBillDetailContainer/forms/useFormClosedBillOrder';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn, useDineInOrderDetail } from '@hooks/dineIn';
import { useAllPaymentMethodOptions } from '@hooks/payments';
import { useCartStore, useCommonStore, useGuestStore } from '@hooks/storage';
import useXenditCcPayment, { OnSuccessXenditCcPaymentData } from '@hooks/xendit/useXenditCcPayment';
import { useQueryProfileDetail } from '@queries/auth';
import { useQueryOrderDetail } from '@queries/order';
import { getRhfError } from '@utils/form';
import {
  getPaymentMethodAndTypeForCustomerOrderPrice,
  getXenditPaymentMethod,
  getXenditPaymentMethodType,
} from '@utils/payment';
import PayToCashierDialog from '../PayToCashierDialog';
import PaymentSuccessDialog from '../PaymentSuccessDialog';

interface CvnForm {
  cvn: string;
}

interface Props {
  isLoading: boolean;
  isUserBalanceEnough: boolean;
  priceDetail: CustomerOrderPriceDetail;
  products: ProductOrder[];
  refetchProductList?: () => void;
}

const ClosedBillPaymentForm = ({
  isUserBalanceEnough = false,
  isLoading: isLoadingDefault = false,
  priceDetail,
  products,
  refetchProductList,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const snackbarContext = useContext(SnackbarContext);
  const { guestData, clearGuestData } = useGuestStore();

  const { dineInDetail: { tableNo = '' } = {} } = useDineInOrderDetail();

  const [showPayToCashierDialog, setShowPayToCashierDialog] = useState(false);
  const [showPaymentSuccessDialog, setPaymentSuccessDialog] = useState(false);
  const [openProfileNameFormDialog, setOpenProfileNameFormDialog] = useState(false);

  const onOpenProfileNameFormDialog = () => setOpenProfileNameFormDialog(true);
  const onCloseProfileNameFormDialog = () => setOpenProfileNameFormDialog(false);
  const { storageState, isFinishInitiated, updateStorage } = useCommonStore();

  const handleClosePaymentSuccessDialog = () => {
    if (guestData) {
      router.push({
        pathname: routes.ROOT,
      });
      return;
    }
    router.push({
      pathname: routes.ORDER_LIST,
      query,
    });
    return;
  };

  const webPushToken = storageState?.firebaseToken;
  const activeLocationId = storageState.activeLocation?.id;
  const { clearCart } = useCartStore(activeLocationId);

  const paymentMethodOptions = useAllPaymentMethodOptions({
    isDineIn: true,
  });

  const { closedBillToken = '' } = useCheckDineIn();

  const {
    query: { orderId: orderIdQueryParam },
  } = router;
  const orderId = Array.isArray(orderIdQueryParam) ? orderIdQueryParam?.[0] : orderIdQueryParam || '';

  const {
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
  } = useFormContext<FormProps>();
  const paymentMethod = watch('paymentMethod');

  const [xenditPaymentUrl, setXenditPaymentUrl] = useState<string | undefined>();
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [virtualAccountNumber, setVirtualAccountNumber] = useState('');
  const [openCvnInputDialog, setOpenCvnInputDialog] = useState(false);

  const onCloseXenditPaymentDialog = () => setXenditPaymentUrl('');
  const onCloseVirtualAccountDialog = () => setVirtualAccountNumber('');
  const onCloseCheckoutDialog = () => setCheckoutUrl('');
  const onCloseQrDialog = () => setQrCode('');
  const onCloseCvnDialog = () => setOpenCvnInputDialog(false);

  const handleOrderPaymentDetail = useCallback(
    ({ customerOrderId, paymentMethod }: { customerOrderId: string; paymentMethod: CustomerOrderPaymentResponse }) => {
      if (
        paymentMethod?.status === 'expired' ||
        paymentMethod?.status === 'cancelled' ||
        paymentMethod?.status === 'refunded'
      ) {
        clearCart();
        clearGuestData();
        updateStorage({ uuid: undefined });
        router.push({
          pathname: routes.PRODUCT_LIST,
          query,
        });
        return;
      }

      router.push({
        pathname: routes.CLOSED_BILL_DETAIL,
        query: {
          ...query,
          ...(typeof router.query?.promoId === 'string' && {
            promoId: router.query?.promoId,
          }),
          orderId: customerOrderId,
        },
      });

      if (paymentMethod?.metadata?.qrString) {
        setQrCode(paymentMethod?.metadata?.qrString);
      }
      if (paymentMethod?.metadata?.virtualAccountNumber) {
        setVirtualAccountNumber(paymentMethod?.metadata?.virtualAccountNumber);
      }
      if (paymentMethod?.metadata?.checkoutUrl) {
        setCheckoutUrl(paymentMethod?.metadata?.checkoutUrl);
      }
    },
    [clearCart, clearGuestData, query, router, updateStorage]
  );

  const { data: orderDetail } = useQueryOrderDetail(orderId, {
    ...ORDER_DETAIL_REFETCH_INTERVAL_OPTIONS,
    enabled: !!orderId,
    onSuccess: (res) => {
      if (res.paymentMethod?.status === 'paid' || (res.status === 'completed' && tableNo)) {
        clearCart();
        clearGuestData();
        updateStorage({ applicablePromoIds: [], uuid: undefined });
        setPaymentSuccessDialog(true);
        return;
      }

      if (res.status === 'completed') {
        clearCart();
        clearGuestData();
        updateStorage({ applicablePromoIds: [], uuid: undefined });
        router.push({
          pathname: routes.ORDER_DETAIL,
          query: {
            ...query,
            orderId: res.id,
          },
        });
        return;
      }

      if (res?.status === 'expired') {
        clearCart();
        updateStorage({ applicablePromoIds: [], uuid: undefined });
        router.replace({
          pathname: routes.CLOSED_BILL_DETAIL,
          query,
        });

        qrCode && setQrCode('');
        checkoutUrl && setCheckoutUrl(checkoutUrl);
        virtualAccountNumber && setVirtualAccountNumber('');
        return;
      }

      if (res?.status === 'waiting_for_cash') {
        setShowPayToCashierDialog(true);
        setValue('paymentMethod.type', 'cash');
      }

      handleOrderPaymentDetail({
        customerOrderId: res.id,
        paymentMethod: res.paymentMethod,
      });
    },
  });

  const handleOnChangePaymentMethod = useCallback(
    (value: PaymentMethod) => {
      setValue('paymentMethod', value);
      clearErrors('paymentMethod');
    },
    [clearErrors, setValue]
  );

  const handleOnSuccessCreateOnlinePayment = useCallback(
    (res: CustomerOrderPaymentResponse, _: CreateOnlinePaymentRequest) => {
      if (res?.status === 'paid') {
        clearCart();
        updateStorage({ uuid: undefined });
        setPaymentSuccessDialog(true);
        return;
      }

      handleOrderPaymentDetail({
        customerOrderId: res.customerOrderId,
        paymentMethod: res,
      });
    },
    [clearCart, handleOrderPaymentDetail, updateStorage]
  );

  const { mutate: onCreateOnlinePayment, isLoading: isCreatingOnlinePayment } = useMutation(createOnlinePayment, {
    onSuccess: handleOnSuccessCreateOnlinePayment,
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const handleOnErrorCreditCardPayment = (message: string) => setError('paymentMethod', { message });
  const handleOnReviewCreditCardPayment = (paymentUrl: string) => setXenditPaymentUrl(paymentUrl);
  const handleOnSuccessCreditCardPayment = useCallback(
    (data: OnSuccessXenditCcPaymentData) => {
      if (data) {
        onCreateOnlinePayment(data);
      }
    },
    [onCreateOnlinePayment]
  );

  const { isXenditLoading, handleOnSuccessCreditCard } = useXenditCcPayment({
    onSuccess: handleOnSuccessCreditCardPayment,
    onReview: handleOnReviewCreditCardPayment,
    onError: handleOnErrorCreditCardPayment,
  });

  const { mutate: onPayClosedBillToCashier, isLoading: isPayingClosedBillToCashier } = useMutation(
    payClosedBillToCashier,
    {
      onError: (e) => {
        const { message = t('error.general_error_label') } = e as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      },
    }
  );

  const { mutate: onCancelPayClosedBillToCashier, isLoading: isCancellingPayClosedBillToCashier } = useMutation(
    cancelPayClosedBillToCashier,
    {
      onSuccess: () => {
        router.push({
          pathname: router.pathname,
          query: {
            ...query,
            ...(typeof router.query?.promoId === 'string' && {
              promoId: router.query?.promoId,
            }),
          },
        });
        setShowPayToCashierDialog(false);
      },
      onError: (e) => {
        const { message = t('error.general_error_label') } = e as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      },
    }
  );

  const { data: profileData } = useQueryProfileDetail({
    enabled: false,
  });

  const handleOnSuccessCreateOrder = useCallback(
    (res: CreateDineInCustomerOrderResponse) => {
      updateStorage({ uuid: uuidv4() });
      if (paymentMethod?.type !== 'credit-card') {
        router.push({
          pathname: router.pathname,
          query: {
            ...query,
            ...(typeof router.query?.promoId === 'string' && {
              promoId: router.query?.promoId,
            }),
            orderId: res.id,
          },
        });
      }
      if (paymentMethod?.type === 'cash') {
        setShowPayToCashierDialog(true);
        onPayClosedBillToCashier({
          customerOrderId: res?.id,
        });
        return;
      }

      if (paymentMethod?.type === 'credit-card') {
        handleOnSuccessCreditCard({
          customerOrderId: res?.id,
          paymentMethod: paymentMethod as CreditCardPaymentMethod,
          totalAmount: res.totalAmountAfterCredit,
        });
        return;
      }

      if (paymentMethod) {
        const orderPaymentMethod = getXenditPaymentMethod(paymentMethod?.type);
        if (orderPaymentMethod) {
          onCreateOnlinePayment({
            customerOrderId: res?.id,
            paymentMethod: orderPaymentMethod,
            paymentMethodType: getXenditPaymentMethodType(paymentMethod),
            mobileNumber: paymentMethod.accountNumber,
            ...(guestData && {
              guestContactNumber: `${guestData?.countryCode}${guestData?.contactNumber}`,
              guestFullname: guestData?.name,
              guestCountryCode: guestData?.countryCode,
            }),
          });
        }
      }
    },
    [
      guestData,
      handleOnSuccessCreditCard,
      onCreateOnlinePayment,
      onPayClosedBillToCashier,
      paymentMethod,
      query,
      router,
      updateStorage,
    ]
  );

  const {
    mutate: onCreateClosedBillOrder,
    isLoading: isCreatingClosedBillOrder,
    data: customerOrder,
  } = useMutation(createDineInCustomerOrder, {
    onSuccess: handleOnSuccessCreateOrder,
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
      refetchProductList?.();
    },
  });

  const onSubmit: SubmitHandler<FormProps> = useCallback(
    (data) => {
      if (!activeLocationId) {
        return;
      }

      if (!profileData?.name && !guestData?.name) {
        onOpenProfileNameFormDialog();
        return;
      }

      if (data?.paymentMethod?.type === 'cash' && storageState.uuid) {
        onCreateClosedBillOrder({
          closedBillDetail: {
            closedBillToken,
          },
          webPushToken,
          priceDetail,
          paymentMethod: 'cash',
          paymentMethodType: null,
          locationId: activeLocationId,
          products,
          guestContactNumber: guestData?.contactNumber,
          guestFullname: guestData?.name,
          guestCountryCode: guestData?.countryCode,
          promoIds: priceDetail.appliedPromos.flatMap((promo) => promo.id),
          uuid: storageState.uuid,
        });
        return;
      }

      if (data.paymentMethod?.type === 'credit-card') {
        const { tokenId, cvc } = data?.paymentMethod as CreditCardPaymentMethod;
        if (tokenId && !cvc) {
          setOpenCvnInputDialog(true);
          return;
        }
      }

      const paymentMethodAndTypeForCustomerOrderPrice =
        getPaymentMethodAndTypeForCustomerOrderPrice(data?.paymentMethod) ?? {};

      if (storageState.uuid) {
        onCreateClosedBillOrder({
          closedBillDetail: {
            closedBillToken,
          },
          webPushToken,
          priceDetail,
          ...paymentMethodAndTypeForCustomerOrderPrice,
          locationId: activeLocationId,
          products,
          guestContactNumber: guestData?.contactNumber,
          guestFullname: guestData?.name,
          guestCountryCode: guestData?.countryCode,
          promoIds: priceDetail.appliedPromos.flatMap((promo) => promo.id),
          uuid: storageState.uuid,
        });
      }
    },
    [
      activeLocationId,
      closedBillToken,
      guestData?.contactNumber,
      guestData?.countryCode,
      guestData?.name,
      onCreateClosedBillOrder,
      priceDetail,
      products,
      profileData?.name,
      storageState.uuid,
      webPushToken,
    ]
  );

  const isLoading =
    isLoadingDefault || isCreatingOnlinePayment || isCreatingClosedBillOrder || isXenditLoading || !isFinishInitiated;

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      isLoading ||
      (!paymentMethod?.type && !isUserBalanceEnough) ||
      (paymentMethod?.type === 'balance' && !isUserBalanceEnough)
    );
  }, [isLoading, isUserBalanceEnough, paymentMethod?.type]);

  const isPayToCashierDialogOpen =
    showPayToCashierDialog || isPayingClosedBillToCashier || isCancellingPayClosedBillToCashier;

  const handleClosePayToCashierDialog = () => {
    if (paymentMethod?.type === 'cash') {
      onCancelPayClosedBillToCashier({
        customerOrderId: customerOrder?.id ?? orderId,
      });
      return;
    }
  };

  const onSubmitCvn = useCallback(
    ({ cvn }: CvnForm) => {
      onCloseCvnDialog();
      const paymentMethodCc = {
        ...paymentMethod,
        type: 'credit-card',
        cvc: cvn,
      } as CreditCardPaymentMethod;

      setValue('paymentMethod', paymentMethodCc);

      handleSubmit((value) => {
        onSubmit({
          ...value,
          paymentMethod: paymentMethodCc,
        });
      })();
    },
    [handleSubmit, onSubmit, paymentMethod, setValue]
  );

  const onCancelPayment = useCallback(() => {
    router.replace({
      pathname: routes.CLOSED_BILL_DETAIL,
      query: {
        ...query,
        ...(typeof router.query?.promoId === 'string' && {
          promoId: router.query?.promoId,
        }),
      },
    });
  }, [query, router]);

  const handleSubmitProfile = () => onCloseProfileNameFormDialog();

  return (
    <Stack direction={'column'} spacing={'xxl'}>
      {!isUserBalanceEnough && (
        <PaymentMethodInput
          error={getRhfError(errors.paymentMethod)}
          onChange={handleOnChangePaymentMethod}
          value={paymentMethod}
          disabled={isLoading}
          paymentMethodOptions={paymentMethodOptions}
          totalAmount={priceDetail.totalAmount}
        />
      )}

      <Button
        isLoading={isLoading}
        disabled={isSubmitButtonDisabled}
        variant={'contained'}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography size={'hm'} variant={'medium'}>
          {t('dine_in.create_payment')}
        </Typography>
      </Button>

      {paymentMethod && (
        <OrderPaymentDialog
          isOpenBill={false}
          paymentMethod={paymentMethod}
          orderId={orderId}
          orderCreatedAt={orderDetail?.createdAt}
          orderTotalAmount={orderDetail?.totalAmount}
          onSubmitCvn={onSubmitCvn}
          xenditPaymentUrl={xenditPaymentUrl}
          onCloseXenditPaymentDialog={onCloseXenditPaymentDialog}
          qrCode={qrCode}
          onCloseQrDialog={onCloseQrDialog}
          onCancelPayment={onCancelPayment}
          checkoutUrl={checkoutUrl}
          onCloseCheckoutDialog={onCloseCheckoutDialog}
          virtualAccountNumber={virtualAccountNumber}
          onCloseVirtualAccountDialog={onCloseVirtualAccountDialog}
          isLoading={isLoading}
          openCvnInputDialog={openCvnInputDialog}
          onCloseCvnDialog={onCloseCvnDialog}
        />
      )}

      <UpdateProfileNameFormDialog
        open={openProfileNameFormDialog}
        onClose={onCloseProfileNameFormDialog}
        onSuccessSubmit={handleSubmitProfile}
      />

      <PayToCashierDialog open={isPayToCashierDialogOpen} onClose={handleClosePayToCashierDialog} />
      <PaymentSuccessDialog open={showPaymentSuccessDialog} onClose={handleClosePaymentSuccessDialog} />
    </Stack>
  );
};

export default ClosedBillPaymentForm;
