import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { useCallback } from 'react';
import { OrderDetailNavbar } from '@components/order';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS, TABLE_NO_QUERY_PARAMS } from '@constants/dineIn';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';

const PaymentSuccessNavbar = () => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const onPressBack = useCallback(() => {
    const {
      [OPEN_BILL_QUERY_PARAMS]: openBillQueryParam,
      [CLOSED_BILL_QUERY_PARAMS]: closedBillQueryParam,
      [TABLE_NO_QUERY_PARAMS]: tableNoQueryParam,
      ...filteredQuery
    } = query;

    if (typeof openBillQueryParam === 'string' && !!openBillQueryParam) {
      router.push({
        pathname: routes.PRODUCT_LIST,
        query: filteredQuery as ParsedUrlQueryInput,
      });

      return;
    }

    if (
      typeof closedBillQueryParam === 'string' &&
      typeof tableNoQueryParam === 'string' &&
      !!closedBillQueryParam &&
      !!tableNoQueryParam
    ) {
      router.push({
        pathname: routes.PRODUCT_LIST,
        query: {
          [CLOSED_BILL_QUERY_PARAMS]: closedBillQueryParam,
          [TABLE_NO_QUERY_PARAMS]: tableNoQueryParam,
          ...filteredQuery,
        } as ParsedUrlQueryInput,
      });
    }

    router.push({
      pathname: routes.PRODUCT_LIST,
      query: filteredQuery as ParsedUrlQueryInput,
    });
  }, [query, router]);

  return <OrderDetailNavbar onPressBack={onPressBack} />;
};

export default PaymentSuccessNavbar;
