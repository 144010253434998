import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, LanguageDialog, MenuDialog, Stack, Typography } from '@components/common';
import { LanguageIcon, MenuIcon } from '@components/icons';
import { DINE_IN_TIME_FORMAT } from '@constants/dineIn';
import { useDineInOrderDetail } from '@hooks/dineIn';
import DineInMergedBills from '../DineInMergedBills';

const DineInHeader = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);

  const onOpen = () => {
    setOpenMenu(true);
  };

  const onClose = () => {
    setOpenMenu(false);
  };

  const onOpenLanguage = () => {
    setOpenLanguageMenu(true);
  };

  const onCloseLanguage = () => {
    setOpenLanguageMenu(false);
  };

  const { dineInDetail, isOpenBill } = useDineInOrderDetail({
    enabled: false,
  });

  const openBillTime = useMemo(() => {
    if (isOpenBill && dineInDetail && 'createdAt' in dineInDetail && dineInDetail?.createdAt) {
      return format(new Date(dineInDetail?.createdAt), DINE_IN_TIME_FORMAT);
    }

    return '';
  }, [dineInDetail, isOpenBill]);

  if (dineInDetail) {
    return (
      <Stack
        direction={'row'}
        rowGap={'m'}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={'s'}
        columnGap={'m'}
      >
        <Stack direction={'column'}>
          <Typography size={'xs'} variant={'medium'}>
            {t('dine_in.welcome', { brand: dineInDetail.brandName, location: dineInDetail.locationName })}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
            <Typography size={'s'} variant={'bold'}>
              {dineInDetail.tableNo
                ? t('dine_in.welcome_table', { table: dineInDetail.tableNo })
                : t('dine_in.pickup_title')}
              &nbsp;
            </Typography>
            {openBillTime && (
              <Typography size={'xs'} variant={'light'}>
                {t('dine_in.start_from', { time: openBillTime })}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack direction={'row'} rowGap={'m'} alignItems={'center'} spacing={'s'}>
          <IconButton padding={0} onClick={onOpenLanguage}>
            <LanguageIcon />
          </IconButton>
          <DineInMergedBills />
          <IconButton onClick={onOpen} padding={0} height={20}>
            <MenuIcon width={20} height={20} />
          </IconButton>
        </Stack>
        <LanguageDialog onClose={onCloseLanguage} open={openLanguageMenu} />
        <MenuDialog open={openMenu} onClose={onClose} />
      </Stack>
    );
  }

  return null;
};

export default DineInHeader;
