import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { OrderDetailNavbar } from '@components/order';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';

const ClosedBillOrderNavbar = () => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const onPressBack = useCallback(() => {
    router.push({
      pathname: routes.PRODUCT_LIST,
      query,
    });
  }, [query, router]);

  return <OrderDetailNavbar onPressBack={onPressBack} />;
};

export default ClosedBillOrderNavbar;
