import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge, Hoverable, IconButton } from '@components/common';
import { BillIcon } from '@components/icons';
import { MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS } from '@constants/dineIn';
import routes from '@constants/routes';
import { useQueryMergedOpenBillOrder } from '@queries/dineIn';

const DineInMergedBills = () => {
  const { query } = useRouter();
  const { data, isFetching } = useQueryMergedOpenBillOrder({
    enabled: true,
    ...MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS,
  });

  if (!isFetching && data?.mergedDetails && data?.mergedDetails?.length > 0) {
    return (
      <Link
        href={{
          pathname: routes.OPEN_BILL_DETAIL,
          query,
        }}
        passHref
      >
        <Hoverable>
          <IconButton>
            <Badge variant={'dot'} showAnimation>
              <BillIcon width={32} height={32} color={'uiDarkPrimary'} />
            </Badge>
          </IconButton>
        </Hoverable>
      </Link>
    );
  }

  return null;
};

export default DineInMergedBills;
