import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Stack, Transition, Typography } from '@components/common';
import { CheckmarkOutlinedLiveIcon, CloseIcon } from '@components/icons';
import routes from '@constants/routes';
import { useInstallPWA, usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { getUserCookies } from '@utils/auth';

interface Props {
  open: boolean;
  onClose: () => void;
}

const PaymentSuccessDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { query } = usePersistentQueryParams();
  const { deferredPromptPWA, handleInstall, shouldShowPwaIos, isPWAStandalone, isIOS } = useInstallPWA();
  const { isLoggedIn } = getUserCookies();
  const { isClosedBill, isOpenBill } = useCheckDineIn();

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} borderRadius={'large'}>
      <Stack alignItems={'end'} padding={'s'}>
        <Button onClick={onClose} variant={'text'}>
          <CloseIcon width={12} height={12} />
        </Button>
      </Stack>
      <Stack
        spacing={'xxl'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingLeft={'xxl'}
        paddingRight={'xxl'}
        paddingBottom={'xxxl'}
        width={'300px'}
      >
        <CheckmarkOutlinedLiveIcon width={56} height={56} />
        <Stack rowGap={'1xxl'} alignItems={'center'}>
          <Stack rowGap={'xxs'} alignItems={'center'}>
            <Typography variant={'bold'} size={'xl'}>
              {isClosedBill ? t('dine_in.submitted_order_title') : t('dine_in.payment_success_page_title')}
            </Typography>
            <Typography variant={'medium'} size={'s'}>
              {t('dine_in.thankyou_description')}
            </Typography>
          </Stack>

          {isOpenBill && (
            <Stack textAlign={'center'}>
              <Typography size={'s'} fontStyle={'italic'}>
                {t('dine_in.order_again_description')}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack rowGap={'m'} textAlign={'center'} width={'100%'}>
          {isClosedBill && (
            <Link
              href={{
                pathname: routes.PRODUCT_LIST,
                query,
              }}
              passHref
            >
              <Button variant={'contained'} color={'primary'} fullWidth>
                <Typography size={'hm'} variant={'medium'}>
                  {t('order.order_again')}
                </Typography>
              </Button>
            </Link>
          )}

          {isOpenBill && (
            <Stack direction={'row'} width={'100%'} columnGap={'s'}>
              <Link
                href={{
                  pathname: routes.ROOT,
                }}
                passHref
              >
                <Button variant={'outlined'} color={'primary'} width={'100%'}>
                  <Typography size={'hm'} variant={'medium'}>
                    {t('order.next_time')}
                  </Typography>
                </Button>
              </Link>
              {!isLoggedIn && (
                <Link
                  href={{
                    pathname: routes.PROFILE_LOGIN,
                    query: {
                      guestMode: true,
                    },
                  }}
                  passHref
                >
                  <Button variant={'contained'} color={'primary'} width={'100%'}>
                    <Typography size={'hm'} variant={'medium'}>
                      {t('menu.login')}
                    </Typography>
                  </Button>
                </Link>
              )}
            </Stack>
          )}

          {shouldShowPwaIos && (
            <Typography isHTML>
              {t('general.install_pwa_in_ios_install_step', {
                image: "<img src='/images/ios-share-icon.png' width='16' height='16' />",
              })}
            </Typography>
          )}

          {!isPWAStandalone && !isIOS && deferredPromptPWA && (
            <Button onClick={handleInstall} variant={'text'}>
              <Typography color={'uiPrimaryMain'}>{t('actions.install_for_delivery_service')}</Typography>
            </Button>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PaymentSuccessDialog;
