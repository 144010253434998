import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { cancelPayMergedOpenBillToCashier, createOpenBillOrder, payMergedOpenBillToCashier } from '@api/dineIn';
import { CreateOpenBillOrderResponse } from '@api/dineIn/types';
import { createOnlinePayment } from '@api/order';
import {
  CreateOnlinePaymentRequest,
  CreditCardPaymentMethod,
  CustomerOrderPaymentResponse,
  PaymentMethod,
} from '@api/order/types';
import { Button, Stack, Typography } from '@components/common';
import { PaymentMethodInput } from '@components/order';
import { OrderPaymentDialog } from '@components/payments';
import { MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS } from '@constants/dineIn';
import routes from '@constants/routes';
import { FormProps } from '@containers/dineIn/OpenBillDetailContainer/forms/useFormOpenBill';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn, useDineInOrderDetail, useMergedOpenBillPriceDetail } from '@hooks/dineIn';
import { useCheckBalanceEnoughForOrder } from '@hooks/orders';
import { useAllPaymentMethodOptions } from '@hooks/payments';
import { useProductPrice } from '@hooks/products';
import { useCartStore, useCommonStore, useGuestStore } from '@hooks/storage';
import { useXenditCcPayment } from '@hooks/xendit';
import { OnSuccessXenditCcPaymentData } from '@hooks/xendit/useXenditCcPayment';
import { useQueryMergedOpenBillOrder } from '@queries/dineIn';
import { getRhfError } from '@utils/form';
import {
  getPaymentMethodAndTypeForCustomerOrderPrice,
  getXenditPaymentMethod,
  getXenditPaymentMethodType,
} from '@utils/payment';
import PayToCashierDialog from '../PayToCashierDialog';
import PaymentSuccessDialog from '../PaymentSuccessDialog';

interface CvnForm {
  cvn: string;
}

const OpenBillPaymentForm = ({ orderId = '', isSpecificPaymentPromo = false }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const promoId = router.query?.promoId;
  const { query } = usePersistentQueryParams();
  const snackbarContext = useContext(SnackbarContext);
  const { guestData } = useGuestStore();

  const [showPayToCashierDialog, setShowPayToCashierDialog] = useState(false);
  const [showPaymentSuccessDialog, setPaymentSuccessDialog] = useState(false);

  const handleClosePaymentSuccessDialog = () => {
    setPaymentSuccessDialog(false);
    if (guestData) {
      router.push({
        pathname: routes.ROOT,
      });
      return;
    }

    router.push({
      pathname: routes.ROOT,
    });
    return;
  };

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
  } = useFormContext<FormProps>();

  const { openBillToken, isDineIn } = useCheckDineIn();
  const { dineInDetail: { tableNo = '' } = {} } = useDineInOrderDetail();
  const { storageState, isFinishInitiated, updateStorage } = useCommonStore();
  const webPushToken = storageState?.firebaseToken;
  const activeLocationId = storageState.activeLocation?.id;
  const { clearCart } = useCartStore(activeLocationId);

  const handleClearCart = useCallback(() => {
    clearCart();
    updateStorage({ uuid: undefined });
  }, [clearCart, updateStorage]);

  const paymentMethodOptions = useAllPaymentMethodOptions({
    isDineIn: true,
  });

  const [xenditPaymentUrl, setXenditPaymentUrl] = useState<string | undefined>();
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [virtualAccountNumber, setVirtualAccountNumber] = useState('');
  const [openCvnInputDialog, setOpenCvnInputDialog] = useState(false);

  const onCloseXenditPaymentDialog = () => setXenditPaymentUrl('');
  const onCloseVirtualAccountDialog = () => setVirtualAccountNumber('');
  const onCloseCheckoutDialog = () => setCheckoutUrl('');
  const onCloseQrDialog = () => setQrCode('');
  const onCloseCvnDialog = () => setOpenCvnInputDialog(false);
  const { clearGuestData } = useGuestStore();

  const paymentMethod = watch('paymentMethod');
  const {
    priceDetail,
    isFetching: isFetchingCalculatePayment,
    isReady,
  } = useMergedOpenBillPriceDetail({
    paymentMethod,
  });

  const { isUserBalanceEnough, isLoadingUserBalance } = useCheckBalanceEnoughForOrder({
    enabled: !!priceDetail && !isFetchingCalculatePayment && isReady,
    priceDetail,
    isDineIn,
  });

  const { isFree: isFreeTotalAmount } = useProductPrice(priceDetail.totalAmount);

  useEffect(() => {
    if (isUserBalanceEnough && !isSpecificPaymentPromo) {
      setValue('paymentMethod', {
        type: 'balance',
      });
      return;
    }

    if (isSpecificPaymentPromo) {
      setValue('paymentMethod', {
        type: 'cash',
      });

      return;
    }

    if (isFreeTotalAmount) {
      setValue('paymentMethod', {
        type: 'balance',
      });
      return;
    }
  }, [isFreeTotalAmount, isSpecificPaymentPromo, isUserBalanceEnough, setValue]);

  const handleOnChangePaymentMethod = useCallback(
    (value: PaymentMethod) => {
      setValue('paymentMethod', value);
      clearErrors('paymentMethod');
    },
    [clearErrors, setValue]
  );

  const handleOrderPaymentDetail = useCallback(
    ({ customerOrderId, paymentMethod }: { customerOrderId: string; paymentMethod: CustomerOrderPaymentResponse }) => {
      if (
        paymentMethod?.status === 'expired' ||
        paymentMethod?.status === 'cancelled' ||
        paymentMethod?.status === 'refunded'
      ) {
        handleClearCart();
        router.push({
          pathname: routes.PRODUCT_LIST,
          query,
        });
        return;
      }

      router.push({
        pathname: routes.OPEN_BILL_DETAIL,
        query: {
          ...query,
          orderId: customerOrderId,
          ...(promoId && {
            promoId,
          }),
        },
      });

      if (paymentMethod?.metadata?.qrString) {
        setQrCode(paymentMethod?.metadata?.qrString);
      }
      if (paymentMethod?.metadata?.virtualAccountNumber) {
        setVirtualAccountNumber(paymentMethod?.metadata?.virtualAccountNumber);
      }
      if (paymentMethod?.metadata?.checkoutUrl) {
        setCheckoutUrl(paymentMethod?.metadata?.checkoutUrl);
      }
    },
    [handleClearCart, promoId, query, router]
  );

  const { data: orderDetail } = useQueryMergedOpenBillOrder({
    enabled: !!orderId,
    ...MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS,
    onSuccess: (res) => {
      if (res.paymentMethod?.status === 'paid' || (res.uniqueId && res.status === 'completed' && tableNo)) {
        handleClearCart();
        clearGuestData();
        updateStorage({ applicablePromoIds: [] });
        setPaymentSuccessDialog(true);
        return;
      }

      if (res.status === 'completed') {
        clearGuestData();
        updateStorage({ applicablePromoIds: [] });
        if (res.isOwner) {
          router.push(`${routes.ORDER_DETAIL}?orderId=${res.uniqueId}`);
          return;
        }

        router.push(routes.PRODUCT_LIST);
        return;
      }

      if (res?.status === 'waiting_for_cash') {
        setShowPayToCashierDialog(true);
        setValue('paymentMethod.type', 'cash');
      }

      handleOrderPaymentDetail({
        customerOrderId: res.uniqueId,
        paymentMethod: res.paymentMethod,
      });
    },
  });

  const handleOnSuccessCreateOnlinePayment = useCallback(
    (res: CustomerOrderPaymentResponse, _: CreateOnlinePaymentRequest) => {
      handleClearCart();
      if (res?.status === 'paid') {
        clearGuestData();
        setPaymentSuccessDialog(true);
        return;
      }

      handleOrderPaymentDetail({
        paymentMethod: res,
        customerOrderId: res.customerOrderId,
      });
    },
    [handleClearCart, clearGuestData, handleOrderPaymentDetail]
  );

  const onCancelPayment = () => {
    router.replace({
      pathname: routes.OPEN_BILL_DETAIL,
      query,
    });
  };

  const { mutate: onCreateOnlinePayment, isLoading: isCreatingOnlinePayment } = useMutation(createOnlinePayment, {
    onSuccess: handleOnSuccessCreateOnlinePayment,
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const handleOnErrorCreditCardPayment = (message: string) => setError('paymentMethod', { message });
  const handleOnReviewCreditCardPayment = (paymentUrl: string) => setXenditPaymentUrl(paymentUrl);
  const handleOnSuccessCreditCardPayment = useCallback(
    (data: OnSuccessXenditCcPaymentData) => {
      if (data) {
        onCreateOnlinePayment({
          ...data,
          uuid: openBillToken,
        });
      }
    },
    [onCreateOnlinePayment, openBillToken]
  );

  const { isXenditLoading, handleOnSuccessCreditCard } = useXenditCcPayment({
    onSuccess: handleOnSuccessCreditCardPayment,
    onReview: handleOnReviewCreditCardPayment,
    onError: handleOnErrorCreditCardPayment,
  });

  const handleOnSuccessCreateOrder = useCallback(
    (res: CreateOpenBillOrderResponse) => {
      if (paymentMethod?.type !== 'credit-card') {
        router.push({
          pathname: router.pathname,
          query: {
            ...query,
            orderId: res.id,
          },
        });
      }
      if (paymentMethod?.type === 'credit-card') {
        handleOnSuccessCreditCard({
          customerOrderId: res?.uniqueId,
          paymentMethod: paymentMethod as CreditCardPaymentMethod,
          totalAmount: res.totalAmountAfterCredit,
        });
        return;
      }

      if (paymentMethod) {
        const orderPaymentMethod = getXenditPaymentMethod(paymentMethod?.type);
        if (orderPaymentMethod) {
          onCreateOnlinePayment({
            uuid: openBillToken,
            customerOrderId: res?.uniqueId,
            paymentMethod: orderPaymentMethod,
            paymentMethodType: getXenditPaymentMethodType(paymentMethod),
            mobileNumber: paymentMethod.accountNumber,
            ...(guestData && {
              guestContactNumber: `${guestData?.countryCode}${guestData?.contactNumber}`,
              guestFullname: guestData?.name,
              guestCountryCode: guestData?.countryCode,
            }),
          });
        }
      }
    },
    [guestData, handleOnSuccessCreditCard, onCreateOnlinePayment, openBillToken, paymentMethod, query, router]
  );

  const { mutate: onCreateOpenBillOrder, isLoading: isCreatingOpenBillOrder } = useMutation(createOpenBillOrder, {
    onSuccess: handleOnSuccessCreateOrder,
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const { mutate: onPayMergedOpenBillToCashier, isLoading: isPayingMergedOpenBillToCashier } = useMutation(
    payMergedOpenBillToCashier,
    {
      onSuccess: () => {
        setShowPayToCashierDialog(true);
      },
      onError: (e) => {
        const { message = t('error.general_error_label') } = e as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      },
    }
  );

  const { mutate: onCancelPayMergedOpenBillToCashier, isLoading: isCancellingPayMergedOpenBillToCashier } = useMutation(
    cancelPayMergedOpenBillToCashier,
    {
      onSuccess: () => {
        setShowPayToCashierDialog(false);
      },
      onError: (e) => {
        const { message = t('error.general_error_label') } = e as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      },
    }
  );

  const onSubmit: SubmitHandler<FormProps> = (data) => {
    if (data?.paymentMethod?.type === 'cash') {
      onPayMergedOpenBillToCashier({
        uuid: openBillToken,
      });
      return;
    }

    if (data.paymentMethod?.type === 'credit-card') {
      const { tokenId, cvc } = data?.paymentMethod as CreditCardPaymentMethod;
      if (tokenId && !cvc) {
        setOpenCvnInputDialog(true);
        return;
      }
    }

    const paymentMethodAndTypeForCustomerOrderPrice =
      getPaymentMethodAndTypeForCustomerOrderPrice(data?.paymentMethod) ?? {};

    onCreateOpenBillOrder({
      uuid: openBillToken,
      webPushToken,
      priceDetail,
      ...paymentMethodAndTypeForCustomerOrderPrice,
      guestContactNumber: guestData?.contactNumber,
      guestFullname: guestData?.name,
      guestCountryCode: guestData?.countryCode,
    });
  };

  const onSubmitCvn = useCallback(
    ({ cvn }: CvnForm) => {
      onCloseCvnDialog();
      const paymentMethodCc = {
        ...paymentMethod,
        type: 'credit-card',
        cvc: cvn,
      } as CreditCardPaymentMethod;

      setValue('paymentMethod', paymentMethodCc);

      handleSubmit(() => {
        const paymentMethodAndTypeForCustomerOrderPrice =
          getPaymentMethodAndTypeForCustomerOrderPrice({
            type: 'credit-card',
            cvc: cvn,
          }) ?? {};

        onCreateOpenBillOrder({
          uuid: openBillToken,
          webPushToken,
          priceDetail,
          ...paymentMethodAndTypeForCustomerOrderPrice,
          guestContactNumber: guestData?.contactNumber,
          guestFullname: guestData?.name,
          guestCountryCode: guestData?.countryCode,
        });
      })();
    },
    [
      guestData?.contactNumber,
      guestData?.countryCode,
      guestData?.name,
      handleSubmit,
      onCreateOpenBillOrder,
      openBillToken,
      paymentMethod,
      priceDetail,
      setValue,
      webPushToken,
    ]
  );

  const isLoading =
    isFetchingCalculatePayment ||
    isLoadingUserBalance ||
    isCreatingOpenBillOrder ||
    isCreatingOnlinePayment ||
    isPayingMergedOpenBillToCashier ||
    isCancellingPayMergedOpenBillToCashier ||
    isXenditLoading ||
    !isFinishInitiated;
  const isSubmitButtonDisabled = useMemo(() => {
    return (
      isLoading ||
      (!paymentMethod?.type && !isUserBalanceEnough) ||
      (paymentMethod?.type === 'balance' && !isUserBalanceEnough)
    );
  }, [isLoading, isUserBalanceEnough, paymentMethod?.type]);

  const isPayToCashierDialogOpen =
    showPayToCashierDialog || isPayingMergedOpenBillToCashier || isCancellingPayMergedOpenBillToCashier;
  const handleClosePayToCashierDialog = () => {
    if (paymentMethod?.type === 'cash') {
      onCancelPayMergedOpenBillToCashier({
        uuid: openBillToken,
      });
      return;
    }
  };

  return (
    <Stack direction={'column'} spacing={'xxl'}>
      {!isUserBalanceEnough && !isSpecificPaymentPromo && (
        <PaymentMethodInput
          error={getRhfError(errors.paymentMethod)}
          onChange={handleOnChangePaymentMethod}
          value={paymentMethod}
          disabled={isLoading}
          paymentMethodOptions={paymentMethodOptions}
          totalAmount={priceDetail.totalAmountFinal ?? '0'}
        />
      )}

      <Button
        isLoading={isLoading}
        disabled={isSubmitButtonDisabled}
        variant={'contained'}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography size={'hm'} variant={'medium'}>
          {t('dine_in.create_payment')}
        </Typography>
      </Button>

      {paymentMethod && (
        <OrderPaymentDialog
          paymentMethod={paymentMethod}
          orderId={orderId}
          orderCreatedAt={orderDetail?.createdAt}
          orderTotalAmount={orderDetail?.totalAmount}
          onSubmitCvn={onSubmitCvn}
          xenditPaymentUrl={xenditPaymentUrl}
          onCloseXenditPaymentDialog={onCloseXenditPaymentDialog}
          qrCode={qrCode}
          onCloseQrDialog={onCloseQrDialog}
          onCancelPayment={onCancelPayment}
          checkoutUrl={checkoutUrl}
          onCloseCheckoutDialog={onCloseCheckoutDialog}
          virtualAccountNumber={virtualAccountNumber}
          onCloseVirtualAccountDialog={onCloseVirtualAccountDialog}
          isLoading={isLoading}
          openCvnInputDialog={openCvnInputDialog}
          onCloseCvnDialog={onCloseCvnDialog}
          isOpenBill
        />
      )}

      <PayToCashierDialog open={isPayToCashierDialogOpen} onClose={handleClosePayToCashierDialog} />
      <PaymentSuccessDialog open={showPaymentSuccessDialog} onClose={handleClosePaymentSuccessDialog} />
    </Stack>
  );
};

export default OpenBillPaymentForm;
